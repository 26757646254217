<div class="grow-container" />

<style lang="scss">
  .grow-container {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    flex-shrink: initial;
    flex-basis: initial;
  }
</style>
