<script lang="ts">
  //
  // © 2025 Hardcore Engineering, Inc. All Rights Reserved.
  // Licensed under the Eclipse Public License v2.0 (SPDX: EPL-2.0).
  //
  export let size: 'small' | 'medium' | 'large' | 'x-large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12,7.2c-0.4,0-0.8,0.3-0.8,0.8v4c0,0.2,0.1,0.4,0.2,0.5l2,2c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-1.8-1.8V8C12.8,7.6,12.4,7.2,12,7.2z"
  />
  <path
    d="M19.6,5.8c-1.6-2-4-3.3-6.6-3.5C7.6,1.7,2.8,5.6,2.3,10.9c0,0.4,0.3,0.8,0.7,0.8c0.4,0,0.8-0.3,0.8-0.7c0.5-4.5,4.5-7.8,9-7.4C15,3.9,17,5,18.4,6.7c1.4,1.7,2,3.9,1.8,6c-0.2,2.2-1.3,4.2-3,5.6c-1.7,1.4-3.9,2-6,1.8c-2.8-0.3-5.2-2-6.5-4.4H8c0.4,0,0.8-0.3,0.8-0.8S8.5,14.2,8,14.2H3.6c0,0,0,0,0,0H3c-0.4,0-0.8,0.3-0.8,0.8v5c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2.8c1.6,2.5,4.2,4.1,7.2,4.4c0.3,0,0.7,0.1,1,0.1c2.2,0,4.4-0.8,6.1-2.2c2-1.6,3.3-4,3.5-6.6C22,10.3,21.2,7.8,19.6,5.8z"
  />
</svg>
