<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M18 9.25004C17.7519 9.25004 17.5319 9.3705 17.3954 9.55613C17.3336 9.63813 17.2713 9.71968 17.2088 9.80109C17.0848 9.96265 16.9054 10.1941 16.6854 10.472C16.2447 11.0287 15.6435 11.7685 14.998 12.5062C14.3494 13.2475 13.6702 13.9703 13.072 14.502C12.7719 14.7687 12.5098 14.9715 12.2954 15.1035C12.1162 15.2137 12.0234 15.241 12.0004 15.2478C11.9774 15.241 11.8837 15.2137 11.7046 15.1035C11.4902 14.9715 11.228 14.7687 10.9279 14.502C10.3297 13.9703 9.65054 13.2475 9.00189 12.5062C8.35639 11.7685 7.75523 11.0287 7.31455 10.472C7.09451 10.1941 6.91516 9.96265 6.79111 9.80109C6.68416 9.6618 6.63656 9.5927 6.57734 9.52135C6.43906 9.35429 6.24172 9.26164 6.0387 9.25103C5.9477 9.24627 5.85556 9.258 5.76709 9.2869C5.67479 9.31702 5.59037 9.36455 5.51771 9.42562C5.45175 9.48091 5.39756 9.54548 5.35562 9.61597C5.28852 9.72831 5.24995 9.85968 5.24995 10C5.24995 10.1721 5.30789 10.3306 5.40531 10.4572C5.47035 10.5432 5.53568 10.6291 5.60138 10.7146C5.72928 10.8812 5.91322 11.1185 6.13849 11.4031C6.58843 11.9714 7.20602 12.7316 7.87302 13.4939C8.53687 14.2526 9.26393 15.0298 9.93137 15.6231C10.2641 15.9189 10.5996 16.1848 10.9184 16.381C11.2131 16.5624 11.5964 16.75 12 16.75C12.4035 16.75 12.7868 16.5624 13.0815 16.381C13.4003 16.1848 13.7358 15.9189 14.0685 15.6231C14.736 15.0298 15.463 14.2526 16.1269 13.4939C16.7939 12.7316 17.4115 11.9714 17.8614 11.4031C18.0867 11.1185 18.2706 10.8812 18.3985 10.7146C18.4644 10.6289 18.5298 10.5429 18.595 10.4566C18.644 10.3929 18.683 10.3211 18.7096 10.2435C18.7413 10.151 18.7541 10.0543 18.7488 9.9589C18.7431 9.85353 18.7157 9.75395 18.6709 9.66456C18.6259 9.57442 18.5621 9.49216 18.4804 9.42408C18.3502 9.31543 18.1827 9.25004 18 9.25004Z"
  />
</svg>
