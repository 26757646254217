<span class="root" />

<style lang="scss">
  .root {
    flex-shrink: 0;
    margin: 0 0.375rem 0 0.875rem;
    width: 1px;
    height: 1.5rem;
    min-width: 1px;
    background-color: var(--theme-list-divider-color);
  }
</style>
