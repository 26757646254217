<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.323 2.75908C17.1886 2.74697 17.0536 2.74697 16.9192 2.75908C16.4453 2.80176 16.0633 3.01871 15.7181 3.29016C15.3944 3.54471 15.0368 3.90235 14.6219 4.31721L4.62624 14.3128C3.85652 15.0818 3.32165 15.6162 3.03524 16.3076C2.74886 16.999 2.74922 17.7549 2.74974 18.8427L2.74978 20.5C2.74978 20.9142 3.08557 21.25 3.49978 21.25L5.15713 21.25C6.24488 21.2506 7.00086 21.2509 7.69225 20.9645C8.38353 20.6782 8.91778 20.1434 9.6863 19.3742L18.0192 11.0413C18.0229 11.0377 18.0266 11.034 18.0303 11.0303C18.034 11.0266 18.0377 11.0229 18.0413 11.0192L19.6816 9.37881C20.097 8.96357 20.4549 8.60566 20.7097 8.28168C20.9811 7.93653 21.1979 7.55457 21.2407 7.08083V6.67653C21.1979 6.2028 20.9811 5.82083 20.7097 5.47568C20.4549 5.15171 20.097 4.7938 19.6817 4.37856L19.6207 4.31765C19.2057 3.90259 18.8479 3.54479 18.5241 3.29015C18.1789 3.01872 17.7969 2.80176 17.323 2.75908ZM17.4999 9.43923L18.5908 8.34834C19.0452 7.89392 19.338 7.59939 19.5305 7.35452C19.7125 7.12305 19.7407 7.01339 19.7468 6.94567C19.7508 6.9011 19.7508 6.85599 19.7467 6.81142C19.7406 6.7437 19.7125 6.63431 19.5305 6.40285C19.338 6.15797 19.0452 5.86345 18.5908 5.40902C18.1363 4.95459 17.8418 4.66183 17.5969 4.46925C17.3654 4.28722 17.256 4.25912 17.1884 4.25303C17.1436 4.24899 17.0986 4.24899 17.0538 4.25303C16.9861 4.25912 16.8768 4.28721 16.6453 4.46924C16.4004 4.66182 16.1059 4.95456 15.6514 5.40901L14.5606 6.49989L17.4999 9.43923ZM13.4999 7.56055L5.78747 15.2729C4.87715 16.1833 4.57906 16.5002 4.42105 16.8816C4.26306 17.263 4.24978 17.6978 4.24978 18.9853V19.75H5.0145C6.30196 19.75 6.73684 19.7367 7.11825 19.5787C7.49963 19.4207 7.81641 19.1227 8.72681 18.2123L16.4392 10.4999L13.4999 7.56055Z"
  />
</svg>
