<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M9.25 6C9.25 6.24809 9.37046 6.46805 9.55608 6.60458C9.63808 6.66631 9.71963 6.72864 9.80104 6.79115C9.9626 6.9152 10.194 7.09455 10.472 7.3146C11.0286 7.75528 11.7684 8.35644 12.5061 9.00193C13.2474 9.65059 13.9702 10.3298 14.5019 10.928C14.7687 11.228 14.9715 11.4902 15.1034 11.7046C15.2137 11.8837 15.241 11.9765 15.2478 11.9995C15.241 12.0225 15.2137 12.1163 15.1034 12.2954C14.9715 12.5098 14.7687 12.7719 14.5019 13.072C13.9702 13.6702 13.2474 14.3494 12.5061 14.9981C11.7684 15.6436 11.0286 16.2447 10.472 16.6854C10.194 16.9054 9.9626 17.0848 9.80104 17.2088C9.66175 17.3158 9.59265 17.3634 9.52131 17.4226C9.35424 17.5609 9.26159 17.7582 9.25098 17.9613C9.24623 18.0523 9.25795 18.1444 9.28685 18.2329C9.31697 18.3252 9.36451 18.4096 9.42558 18.4822C9.48086 18.5482 9.54543 18.6024 9.61593 18.6443C9.72827 18.7114 9.85963 18.75 10 18.75C10.1721 18.75 10.3306 18.6921 10.4571 18.5946C10.5432 18.5296 10.629 18.4643 10.7146 18.3986C10.8811 18.2707 11.1185 18.0867 11.403 17.8615C11.9714 17.4115 12.7316 16.7939 13.4939 16.1269C14.2526 15.4631 15.0298 14.736 15.6231 14.0686C15.9188 13.7359 16.1847 13.4004 16.3809 13.0815C16.5623 12.7868 16.75 12.4035 16.75 12C16.75 11.5965 16.5623 11.2132 16.3809 10.9184C16.1847 10.5996 15.9188 10.2641 15.6231 9.93141C15.0298 9.26397 14.2526 8.53691 13.4939 7.87307C12.7316 7.20606 11.9714 6.58847 11.403 6.13853C11.1185 5.91326 10.8811 5.72932 10.7146 5.60142C10.6288 5.53559 10.5428 5.47013 10.4566 5.40496C10.3929 5.35597 10.321 5.317 10.2434 5.29039C10.151 5.25865 10.0543 5.24582 9.95885 5.25111C9.85349 5.25681 9.7539 5.28425 9.66451 5.32903C9.57438 5.37401 9.49211 5.43783 9.42404 5.51959C9.31538 5.64971 9.25 5.81722 9.25 6Z"
  />
</svg>
