<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M22.3,7.5v-4c0-1-0.8-1.8-1.8-1.8h-17c-1,0-1.8,0.8-1.8,1.8v4.1c0,1,0.8,1.8,1.8,1.8h17.1C21.5,9.3,22.3,8.5,22.3,7.5z M9.2,7.8V3.2h5v4.6H9.2z M3.7,3.2h4v4.6h-4c-0.3,0-0.5-0.2-0.5-0.5V3.7C3.2,3.4,3.4,3.2,3.7,3.2zM20.8,7.3c0,0.3-0.2,0.5-0.5,0.5h-4.6V3.2h4.6c0.3,0,0.5,0.2,0.5,0.5V7.3z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
  <path
    d="M21.7,17.9h-2.2v-2.2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8V18h-2.2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.2v2.1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2.3h2.2c0.4,0,0.8-0.3,0.8-0.8S22.1,17.9,21.7,17.9z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
  <path
    d="M13,18.3l-4,4c-0.3,0.3-0.8,0.3-1.1,0l-4-4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l2.7,2.7v-6.2C7.7,13.3,8,13,8.4,13c0.4,0,0.8,0.3,0.8,0.8v6.2l2.7-2.7c0.3-0.3,0.8-0.3,1.1,0S13.3,18,13,18.3z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
</svg>
