<!--
// Copyright © 2022 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  import type { Class, Doc } from '@hcengineering/core'
  import { getClient } from '@hcengineering/presentation'
  import { Component, Label } from '@hcengineering/ui'
  import view from '@hcengineering/view'

  export let value: Class<Doc>

  $: presenters =
    value !== undefined ? getClient().getHierarchy().findMixinMixins(value, view.mixin.ObjectPresenter) : []
</script>

<div class="flex-row-center text-nowrap">
  <Label label={value.label} />
  {#if presenters.length > 0}
    {#each presenters as presenter}
      <Component is={presenter.presenter} props={{ value }} />
    {/each}
  {/if}
</div>
