<script lang="ts">
  //
  // © 2025 Hardcore Engineering, Inc. All Rights Reserved.
  // Licensed under the Eclipse Public License v2.0 (SPDX: EPL-2.0).
  //
  export let size: 'small' | 'medium' | 'large' | 'x-large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6.5,10.8c2.3,0,4.2-1.9,4.2-4.2S8.8,2.2,6.5,2.2S2.2,4.2,2.2,6.5S4.2,10.8,6.5,10.8z M6.5,3.8C8,3.8,9.2,5,9.2,6.5S8,9.2,6.5,9.2S3.8,8,3.8,6.5S5,3.8,6.5,3.8z"
  />
  <path
    d="M7.2,13.6c-0.3-0.5-1-0.5-1.3,0l-4,7c-0.1,0.2-0.1,0.5,0,0.7c0.1,0.2,0.4,0.4,0.6,0.4h8c0.3,0,0.5-0.1,0.6-0.4c0.1-0.2,0.1-0.5,0-0.7L7.2,13.6z M3.8,20.2l2.7-4.7l2.7,4.7H3.8z"
  />
  <path
    d="M18.6,6.5l3-3c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0l-3,3l-3-3c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l3,3l-3,3c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l3-3l3,3c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L18.6,6.5z"
  />
  <path
    d="M21,13.2h-7c-0.4,0-0.8,0.3-0.8,0.8v7c0,0.4,0.3,0.8,0.8,0.8h7c0.4,0,0.8-0.3,0.8-0.8v-7C21.8,13.6,21.4,13.2,21,13.2zM20.2,20.2h-5.5v-5.5h5.5V20.2z"
  />
</svg>
