<script lang="ts">
  //
  // © 2025 Hardcore Engineering, Inc. All Rights Reserved.
  // Licensed under the Eclipse Public License v2.0 (SPDX: EPL-2.0).
  //
  export let size: 'small' | 'medium' | 'large' | 'x-large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.6,7.6C7.7,7.7,7.8,7.8,8,7.8c0.2,0,0.5-0.1,0.6-0.3c0.2-0.3,0.2-0.8-0.2-1C8,6.1,7.7,5.6,7.8,5c0-0.5,0.2-1.1,0.7-1.4c0.3-0.2,0.4-0.7,0.2-1c-0.2-0.3-0.7-0.4-1-0.2C6.7,3,6.2,4,6.2,5C6.2,6,6.7,7,7.6,7.6z"
  />
  <path
    d="M11.6,7.6c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.6-0.3c0.2-0.3,0.2-0.8-0.2-1C12,6.1,11.7,5.6,11.8,5c0-0.5,0.2-1.1,0.7-1.4c0.3-0.2,0.4-0.7,0.2-1c-0.2-0.3-0.7-0.4-1-0.2C10.7,3,10.2,4,10.2,5C10.2,6,10.7,7,11.6,7.6z"
  />
  <path
    d="M19.6,10.6c-0.6-0.3-1.2-0.4-1.8-0.3V10c0-0.4-0.3-0.8-0.8-0.8H3c-0.4,0-0.8,0.3-0.8,0.8v5c0,3.7,3,6.8,6.8,6.8h2c2.8,0,5.2-1.7,6.2-4.1c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.3-0.2c0.9-0.3,1.7-1,2.1-2C22.3,13.7,21.4,11.5,19.6,10.6zM16.2,10.8v0.4c0,0,0,0,0,0v1.6c-0.8-0.3-1.7-0.5-2.7-0.5c-1.5,0-3,0.4-4,1.2c-0.7,0.5-1.8,0.8-3.1,0.8c-1.1,0-2-0.2-2.7-0.7v-2.8H16.2z M11,20.2H9c-2.8,0-5.1-2.2-5.2-5c0.8,0.3,1.7,0.5,2.6,0.5c0.1,0,0.1,0,0.2,0c1.5,0,2.9-0.4,3.9-1.2c0.7-0.5,1.8-0.8,3-0.8c0.1,0,0.1,0,0.2,0c1,0,2,0.2,2.7,0.7V15C16.2,17.9,13.9,20.2,11,20.2z M20,14.9c-0.2,0.5-0.7,1-1.3,1.2c-0.4,0.1-0.8,0.2-1.1,0.1c0.1-0.4,0.1-0.8,0.1-1.2v-3.2c0.4,0,0.8,0,1.2,0.2C20.1,12.5,20.6,13.8,20,14.9z"
  />
</svg>
