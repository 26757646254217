<!--
// Copyright © 2020, 2021 Anticrm Platform Contributors.
// Copyright © 2021 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8 3.99988C7.50555 3.99988 7.0222 4.1465 6.61108 4.4212C6.19995 4.69591 5.87952 5.08635 5.6903 5.54317C5.50108 5.99998 5.45158 6.50265 5.54804 6.9876C5.6445 7.47256 5.88261 7.91801 6.23224 8.26764C6.58187 8.61728 7.02733 8.85538 7.51228 8.95184C7.99723 9.0483 8.4999 8.9988 8.95671 8.80958C9.41353 8.62036 9.80397 8.29993 10.0787 7.8888C10.3534 7.47768 10.5 6.99433 10.5 6.49988C10.5 5.83684 10.2366 5.20095 9.76777 4.73211C9.29893 4.26327 8.66304 3.99988 8 3.99988ZM8 7.99988C7.70333 7.99988 7.41332 7.9119 7.16665 7.74708C6.91997 7.58226 6.72771 7.34799 6.61418 7.0739C6.50065 6.79981 6.47095 6.49821 6.52882 6.20724C6.5867 5.91627 6.72956 5.649 6.93934 5.43922C7.14912 5.22944 7.4164 5.08658 7.70737 5.0287C7.99834 4.97082 8.29994 5.00053 8.57403 5.11406C8.84812 5.22759 9.08239 5.41985 9.24721 5.66652C9.41203 5.9132 9.5 6.20321 9.5 6.49988C9.49955 6.89756 9.34137 7.27883 9.06017 7.56004C8.77896 7.84125 8.39769 7.99943 8 7.99988Z"
  />
  <path
    d="M8 0.999878C6.61553 0.999878 5.26216 1.41042 4.11101 2.17959C2.95987 2.94876 2.06266 4.04201 1.53285 5.32109C1.00303 6.60018 0.86441 8.00764 1.13451 9.36551C1.4046 10.7234 2.07129 11.9707 3.05026 12.9496C4.02922 13.9286 5.2765 14.5953 6.63437 14.8654C7.99224 15.1355 9.3997 14.9968 10.6788 14.467C11.9579 13.9372 13.0511 13.04 13.8203 11.8889C14.5895 10.7377 15 9.38435 15 7.99988C14.9979 6.144 14.2597 4.36474 12.9474 3.05244C11.6351 1.74014 9.85588 1.00197 8 0.999878ZM5 13.1881V12.4999C5.00044 12.1022 5.15862 11.7209 5.43983 11.4397C5.72104 11.1585 6.10231 11.0003 6.5 10.9999H9.5C9.89769 11.0003 10.279 11.1585 10.5602 11.4397C10.8414 11.7209 10.9996 12.1022 11 12.4999V13.1881C10.0896 13.7197 9.05426 13.9999 8 13.9999C6.94574 13.9999 5.91042 13.7197 5 13.1881ZM11.9963 12.4628C11.9863 11.8069 11.7191 11.1812 11.2521 10.7205C10.7852 10.2598 10.156 10.001 9.5 9.99988H6.5C5.84405 10.001 5.2148 10.2598 4.74786 10.7205C4.28093 11.1812 4.01369 11.8069 4.00375 12.4628C3.09703 11.6532 2.45762 10.5872 2.17017 9.40611C1.88272 8.22501 1.9608 6.98445 2.39407 5.84871C2.82734 4.71297 3.59536 3.73561 4.59644 3.04606C5.59751 2.35651 6.78442 1.98729 8 1.98729C9.21558 1.98729 10.4025 2.35651 11.4036 3.04606C12.4046 3.73561 13.1727 4.71297 13.6059 5.84871C14.0392 6.98445 14.1173 8.22501 13.8298 9.40611C13.5424 10.5872 12.903 11.6532 11.9963 12.4628Z"
  />
</svg>
