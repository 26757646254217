<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M9.3,20.5v-17c0-1-0.8-1.8-1.7-1.8H3.5c-1,0-1.8,0.8-1.8,1.8v17c0,1,0.8,1.8,1.8,1.8h4.1C8.5,22.2,9.3,21.4,9.3,20.5zM7.8,14.1H3.2v-5h4.6V14.1z M3.7,3.2h3.6c0.3,0,0.5,0.2,0.5,0.5v4H3.2v-4C3.2,3.4,3.4,3.2,3.7,3.2z M3.7,20.7c-0.3,0-0.5-0.2-0.5-0.5v-4.6h4.6v4.6c0,0.3-0.2,0.5-0.5,0.5H3.7z"
  />
  <path
    d="M21.7,17.9h-2.2v-2.2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8V18h-2.2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.2v2.1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2.3h2.2c0.4,0,0.8-0.3,0.8-0.8S22.1,17.9,21.7,17.9z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
  <path
    d="M18.3,3.7l4,4c0.3,0.3,0.3,0.8,0,1.1l-4,4c-0.3,0.3-0.8,0.3-1.1,0c-0.3-0.3-0.3-0.8,0-1.1L19.9,9h-6.2C13.3,9,13,8.7,13,8.2c0-0.4,0.3-0.8,0.8-0.8h6.2l-2.7-2.7c-0.3-0.3-0.3-0.8,0-1.1S18,3.4,18.3,3.7z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
</svg>
