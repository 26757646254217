<!--
// Copyright © 2020, 2021 Anticrm Platform Contributors.
// Copyright © 2021 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  const fill: string = 'var(--dark-color)'
</script>

<svg class="svg-expand" {fill} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <polygon points="32.2,15.6 31.4,16.4 46.9,32 31.4,47.6 32.2,48.4 48.6,32 " />
  <polygon points="32.6,32 16.2,15.6 15.4,16.4 30.9,32 15.4,47.6 16.2,48.4 " />
</svg>

<style lang="scss">
  .svg-expand {
    width: 4rem;
    height: 4rem;
  }
</style>
