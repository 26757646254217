<!--
// Copyright © 2020, 2021 Anticrm Platform Contributors.
// Copyright © 2021 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  import { IntlString } from '@hcengineering/platform'
  import NumberEditor from './NumberEditor.svelte'

  export let value: number | undefined
  export let label: IntlString
  export let onChange: ((value: number | undefined) => void) | undefined = undefined
  export let kind: 'no-border' | 'link' | 'list' = 'link'
  export let readonly = false
</script>

{#if onChange !== undefined && !readonly}
  <NumberEditor {onChange} {value} {label} {kind} />
{:else}
  <span>{value || ''}</span>
{/if}
