//
// Copyright © 2023 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
//

import core, { type IdMap, type Status, toIdMap } from '@hcengineering/core'
import { createQuery, onClient } from '@hcengineering/presentation'
import { writable } from 'svelte/store'

interface Store {
  byId: IdMap<Status>
  array: Status[]
}

// Issue status live query
export const statusStore = writable<Store>({
  byId: new Map(),
  array: []
})

const query = createQuery(true)
onClient(() => {
  query.query(core.class.Status, {}, (res) => {
    const obj = {
      byId: toIdMap(res),
      array: res
    }
    statusStore.set(obj)
  })
})
