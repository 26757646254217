<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M3.11905 18L4.32075 14.5355H9.4344L10.6297 18H12.7263L8.01535 4.90906H5.7334L1.02246 18H3.11905ZM4.89604 12.8735L6.82644 7.28689H6.92871L8.8591 12.8735H4.89604Z"
  />
  <path
    d="M17.3207 18.2175C18.9442 18.2175 19.8583 17.3928 20.2226 16.6578H20.2993V18.0001H22.1659V11.4802C22.1659 8.62297 19.9159 8.05408 18.3562 8.05408C16.5791 8.05408 14.9428 8.76998 14.3036 10.5598L16.0997 10.9688C16.381 10.2721 17.0969 9.60095 18.3817 9.60095C19.6154 9.60095 20.2483 10.2465 20.2483 11.3588V11.4035C20.2483 12.1002 19.5323 12.0875 17.768 12.292C15.908 12.5093 14.0032 12.9951 14.0032 15.2259C14.0032 17.1564 15.4541 18.2175 17.3207 18.2175ZM17.7361 16.6833C16.6559 16.6833 15.8761 16.1976 15.8761 15.2515C15.8761 14.2288 16.7837 13.8644 17.8895 13.7174C18.5096 13.6343 19.9798 13.4681 20.2546 13.1933V14.4589C20.2546 15.6223 19.3277 16.6833 17.7361 16.6833Z"
  />
</svg>
