<!--
// Copyright © 2020, 2021 Anticrm Platform Contributors.
// Copyright © 2021 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12,10.8c2.3,0,4.2-1.9,4.2-4.2S14.3,2.2,12,2.2S7.8,4.2,7.8,6.5S9.7,10.8,12,10.8z M12,3.8 c1.5,0,2.8,1.2,2.8,2.8S13.5,9.2,12,9.2S9.2,8,9.2,6.5S10.5,3.8,12,3.8z"
  />
  <path
    d="M6.8,9.9c0.2-0.4,0.1-0.8-0.3-1C5.8,8.4,5.2,7.5,5.2,6.5c0-0.9,0.5-1.8,1.2-2.3c0.3-0.2,0.4-0.7,0.2-1 C6.4,2.9,5.9,2.8,5.6,3c-1.1,0.8-1.8,2-1.8,3.5c0,1.5,0.8,2.9,2.1,3.6C6.2,10.4,6.6,10.2,6.8,9.9z"
  />
  <path
    d="M18.4,3c-0.3-0.2-0.8-0.2-1,0.2c-0.2,0.3-0.2,0.8,0.2,1c0.7,0.5,1.2,1.3,1.2,2.3c0,0.9-0.5,1.8-1.2,2.3 c-0.3,0.2-0.4,0.7-0.2,1c0.2,0.3,0.7,0.4,1,0.2c1.1-0.8,1.8-2,1.8-3.5C20.3,5.1,19.5,3.8,18.4,3z"
  />
  <path d="M8.2,13.6l1.6-0.3C9.2,13.3,8.7,13.4,8.2,13.6z" />
  <path d="M9.8,13.3l2.2,0C11.1,13.2,10.3,13.2,9.8,13.3z" />
  <path
    d="M18.4,16.2c-0.5-1.2-1.4-2.1-2.6-2.6c-0.5-0.2-1-0.3-1.6-0.3c-0.6,0-1.3,0-2.2,0H12l-3.8,0.4 C7,14.1,6.1,15,5.6,16.2c-0.2,0.5-0.3,1-0.3,1.6c0,0.6,0,1.3,0,2.2v1c0,0.4,0.3,0.8,0.8,0.8h12c0.4,0,0.8-0.3,0.8-0.8v-1 c0-0.9,0-1.6,0-2.2C18.7,17.2,18.6,16.7,18.4,16.2z M17.2,20.2H6.8V20c0-0.9,0-1.6,0-2.1c0-0.5,0.1-0.8,0.2-1.1 C7.3,16,8,15.3,8.8,15c0.3-0.1,0.6-0.2,1.1-0.2c0.5,0,1.2,0,2.1,0s1.6,0,2.1,0c0.5,0,0.8,0.1,1.1,0.2c0.8,0.3,1.4,1,1.8,1.8 c0.1,0.3,0.2,0.6,0.2,1.1c0,0.5,0,1.2,0,2.1V20.2z"
  />
  <path
    d="M22.7,17.7c-0.1-0.7-0.2-1.3-0.5-1.9c-0.5-0.9-1.2-1.6-2.1-2.1c-0.4-0.2-0.8,0-1,0.3c-0.2,0.4,0,0.8,0.3,1 c0.3,0.2,0.6,0.4,0.8,0.6l0,0c0,0,0,0,0,0c0.2,0.2,0.4,0.5,0.6,0.8c0.2,0.3,0.3,0.7,0.3,1.3c0.1,0.6,0.1,1.4,0.1,2.6V21 c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.6C22.8,19.3,22.8,18.4,22.7,17.7z"
  />
  <path d="M19.5,15.1l1.4,1.4l-0.6-0.8C20.1,15.5,19.8,15.3,19.5,15.1z" />
  <path
    d="M3.8,13.8c-0.9,0.5-1.6,1.2-2.1,2.1c-0.3,0.6-0.4,1.2-0.5,1.9c-0.1,0.7-0.1,1.6-0.1,2.7V21 c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-0.6c0-1.1,0-1.9,0.1-2.6c0.1-0.6,0.1-1,0.3-1.3c0.3-0.6,0.8-1.1,1.4-1.4 c0.4-0.2,0.5-0.6,0.3-1C4.7,13.7,4.2,13.6,3.8,13.8z"
  />
</svg>
