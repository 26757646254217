<script lang="ts">
  //
  // © 2025 Hardcore Engineering, Inc. All Rights Reserved.
  // Licensed under the Eclipse Public License v2.0 (SPDX: EPL-2.0).
  //
  export let size: 'small' | 'medium' | 'large' | 'x-large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12,7.8c0.4,0,0.8-0.3,0.8-0.8S12.4,6.2,12,6.2c-3.2,0-5.8,2.6-5.8,5.8c0,3.2,2.6,5.8,5.8,5.8c3.2,0,5.8-2.6,5.8-5.8c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2C7.8,9.7,9.7,7.8,12,7.8z"
  />
  <path
    d="M21.7,10.9c0-0.4-0.4-0.7-0.8-0.7c-0.4,0-0.7,0.4-0.7,0.8c0.1,0.6,0.1,1.2,0,1.8c-0.2,2.2-1.3,4.2-3.1,5.5c-1.7,1.4-3.9,2-6.1,1.8c-2.2-0.2-4.2-1.3-5.5-3.1c-1.4-1.7-2-3.9-1.8-6.1C4,8.9,5.1,6.9,6.8,5.6s3.9-2,6.1-1.8c0.4,0.1,0.8-0.2,0.8-0.7c0-0.4-0.2-0.8-0.7-0.8C10.5,2,7.9,2.8,5.9,4.4c-2,1.6-3.3,3.9-3.6,6.5C2,13.5,2.8,16,4.4,18.1c1.6,2,3.9,3.3,6.5,3.6c0.4,0,0.7,0.1,1.1,0.1c2.2,0,4.3-0.7,6.1-2.1c2-1.6,3.3-3.9,3.6-6.5C21.8,12.4,21.8,11.6,21.7,10.9z"
  />
  <path
    d="M12.6,10.4c-0.2-0.1-0.4-0.1-0.6-0.1c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8c0-0.2,0-0.4-0.1-0.6l1.7-1.7H18c0.2,0,0.4-0.1,0.5-0.2l3-3c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.4-0.5-0.7-0.5h-2.2V3c0-0.3-0.2-0.6-0.5-0.7c-0.3-0.1-0.6-0.1-0.8,0.2l-3,3c-0.1,0.1-0.2,0.3-0.2,0.5v2.7L12.6,10.4z M15.8,6.3l1.5-1.5V6c0,0.4,0.3,0.8,0.8,0.8h1.2l-1.5,1.5h-1.9V6.3z"
  />
</svg>
