<script lang="ts">
  //
  // © 2025 Hardcore Engineering, Inc. All Rights Reserved.
  // Licensed under the Eclipse Public License v2.0 (SPDX: EPL-2.0).
  //
  export let size: 'small' | 'medium' | 'large' | 'x-large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12,2.2c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S17.4,2.2,12,2.2z M12,20.2c-4.5,0-8.2-3.7-8.2-8.2S7.5,3.8,12,3.8s8.2,3.7,8.2,8.2S16.5,20.2,12,20.2z"
  />
  <path
    d="M7.8,9.5c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.6,0.4,0.9,0.4c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2C9.9,9.8,10,9.8,10,9.7c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.3-0.1-0.7-0.4-0.9C9.8,8.1,9.8,8,9.7,8c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.4-0.1-0.8,0-1.1,0.3C7.9,8.3,7.8,8.7,7.8,9c0,0.1,0,0.2,0,0.2C7.8,9.3,7.8,9.4,7.8,9.5z"
  />
  <path
    d="M14.3,10c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0s0.2,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.6,0.4-0.9c0-0.3-0.1-0.7-0.4-0.9c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9C14.2,9.9,14.2,10,14.3,10z"
  />
  <path
    d="M16,12.2H8c-0.4,0-0.8,0.3-0.8,0.8c0,2.6,2.1,4.8,4.8,4.8s4.8-2.1,4.8-4.8C16.8,12.6,16.4,12.2,16,12.2z M12,16.2c-1.5,0-2.8-1.1-3.2-2.5h6.3C14.8,15.2,13.5,16.2,12,16.2z"
  />
</svg>
