<script lang="ts">
  //
  // © 2025 Hardcore Engineering, Inc. All Rights Reserved.
  // Licensed under the Eclipse Public License v2.0 (SPDX: EPL-2.0).
  //
  export let size: 'small' | 'medium' | 'large' | 'x-large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
  <path d="M10,9.8c-0.7,0-1.2,0.6-1.2,1.3s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v0C11.2,10.3,10.7,9.8,10,9.8z" />
  <path d="M14,9.8c-0.7,0-1.2,0.6-1.2,1.3s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v0C15.2,10.3,14.7,9.8,14,9.8z" />
  <path
    d="M21.7,10.6l-2-6.8c-0.1-0.4-0.5-0.6-0.8-0.5l-6,1c0,0,0,0,0,0h-1.7c0,0,0,0,0,0l-6-1C4.7,3.2,4.4,3.4,4.3,3.8l-2,6.9c-0.1,0.6,0.1,1.3,0.6,1.7c0.3,0.3,0.7,0.4,1.1,0.4c0.1,0,0.2,0,0.3,0c0.8,5.3,2.7,8,5.7,8h4c3,0,4.9-2.7,5.7-8c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.1,1.1-0.4C21.6,11.9,21.9,11.3,21.7,10.6z M3.8,11.2c-0.1,0-0.1-0.1-0.1-0.2l1.8-6.2l4,0.6l-5.3,5.7C4.1,11.3,3.9,11.3,3.8,11.2z M14,19.2h-1.2V18c0-0.1,0-0.2-0.1-0.4c0.7-0.2,1.1-0.8,1.1-1.6c0-0.4-0.3-0.8-0.8-0.8h-2c-0.4,0-0.8,0.3-0.8,0.8c0,0.8,0.4,1.4,1.1,1.6c-0.1,0.1-0.1,0.2-0.1,0.4v1.2H10c-2.2,0-3.6-2.5-4.3-7.3c0-0.1,0-0.1,0-0.1l5.6-6h1.4l5.6,6c0,0,0,0.1,0,0.1C17.6,16.8,16.2,19.2,14,19.2z M20.2,11.2c-0.1,0.1-0.3,0.1-0.4,0l-5.3-5.7l4-0.6l1.8,6.1C20.3,11.1,20.2,11.1,20.2,11.2z"
  />
</svg>
