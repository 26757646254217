<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.54958 14.4003C7.88078 14.1516 8.35093 14.2184 8.59968 14.5496C9.37627 15.5835 10.6102 16.25 12 16.25C13.3898 16.25 14.6237 15.5835 15.4003 14.5496C15.6491 14.2184 16.1192 14.1516 16.4504 14.4003C16.7816 14.6491 16.8484 15.1192 16.5997 15.4504C15.552 16.8453 13.8816 17.75 12 17.75C10.1184 17.75 8.44797 16.8453 7.40031 15.4504C7.15155 15.1192 7.21838 14.6491 7.54958 14.4003Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 9C7 8.44772 7.44772 8 8 8H8.00897C8.56125 8 9.00897 8.44772 9.00897 9C9.00897 9.55228 8.56125 10 8.00897 10H8C7.44772 10 7 9.55228 7 9ZM14.991 9C14.991 8.44772 15.4387 8 15.991 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H15.991C15.4387 10 14.991 9.55228 14.991 9Z"
  />
</svg>
