<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  const fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21.8,20.7l-2-2l1.9-1.9c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.7-0.3-1.1,0l-1.9,1.9l-1.9-1.9c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l1.9,1.9l-2,2c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l2-2l2,2c0.3,0.3,0.8,0.3,1.1,0S22.1,21,21.8,20.7z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
  <path
    d="M16,13.3V8.8h4.2V10v3.3c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V10V7V4.5c0.1-1.2-1-2.3-2.2-2.3h-15c-1.2,0-2.2,1-2.2,2.2v2v3v10c0,1.2,1,2.2,2.2,2.2H13c0.4,0,0.7-0.3,0.7-0.6c0-0.4-0.3-0.7-0.7-0.7H9.5v-0.1V8.8h5v4.5c0,0.4,0.3,0.8,0.8,0.8S16,13.7,16,13.3z M8,20.3H4.5c-0.4-0.1-0.7-0.4-0.7-0.8v-10V8.8H8V20.3z M3.8,7.2V6.5v-2c0-0.4,0.3-0.8,0.8-0.8h15c0.4,0,0.8,0.3,0.8,0.8V7v0.2H3.8z"
    fill-rule="evenodd"
    clip-rule="evenodd"
  />
</svg>
