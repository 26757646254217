<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M13.25 4C13.25 3.30964 12.6904 2.75 12 2.75C11.3096 2.75 10.75 3.30964 10.75 4V10.75H4C3.30964 10.75 2.75 11.3096 2.75 12C2.75 12.6904 3.30964 13.25 4 13.25H10.75V20C10.75 20.6904 11.3096 21.25 12 21.25C12.6904 21.25 13.25 20.6904 13.25 20V13.25H20C20.6904 13.25 21.25 12.6904 21.25 12C21.25 11.3096 20.6904 10.75 20 10.75H13.25V4Z"
  />
</svg>
