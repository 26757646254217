<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.25 12C2.25 6.61523 6.61523 2.25 12 2.25C17.3848 2.25 21.75 6.61523 21.75 12V12.9003C21.75 14.8057 20.2054 16.3503 18.3 16.3503C17.0704 16.3503 15.991 15.7071 15.3801 14.7387C14.5825 15.7219 13.3646 16.3503 12 16.3503C9.5976 16.3503 7.65 14.4028 7.65 12.0003C7.65 9.59788 9.59759 7.65033 12 7.65033C13.0901 7.65033 14.0866 8.05135 14.85 8.71391V8.40033C14.85 7.98612 15.1858 7.65033 15.6 7.65033C16.0142 7.65033 16.35 7.98612 16.35 8.40033V12.9003C16.35 13.9773 17.223 14.8503 18.3 14.8503C19.377 14.8503 20.25 13.9773 20.25 12.9003V12C20.25 7.44365 16.5564 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5564 7.44365 20.25 12 20.25C13.8585 20.25 15.5714 19.6364 16.9504 18.6004C17.2816 18.3516 17.7517 18.4183 18.0005 18.7495C18.2493 19.0807 18.1826 19.5508 17.8514 19.7996C16.2216 21.024 14.1945 21.75 12 21.75C6.61523 21.75 2.25 17.3848 2.25 12ZM14.85 12.0003C14.85 10.4263 13.574 9.15033 12 9.15033C10.426 9.15033 9.15 10.4263 9.15 12.0003C9.15 13.5744 10.426 14.8503 12 14.8503C13.574 14.8503 14.85 13.5744 14.85 12.0003Z"
  />
</svg>
