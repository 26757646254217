<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  export let fill: string = 'currentColor'
</script>

<svg class="svg-{size}" {fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M19.5,2.2h-15c-1.2,0-2.2,1-2.2,2.2v15c0,1.2,1,2.2,2.2,2.2h15c1.2,0,2.2-1,2.2-2.2v-15 C21.8,3.3,20.7,2.2,19.5,2.2z M20.2,19.5c0,0.4-0.3,0.8-0.8,0.8h-15c-0.4,0-0.8-0.3-0.8-0.8v-15c0-0.4,0.3-0.8,0.8-0.8h15 c0.4,0,0.8,0.3,0.8,0.8V19.5z"
  />
  <path
    d="M6.5,11.2h4c0.4,0,0.8-0.3,0.8-0.8v-4c0-0.4-0.3-0.8-0.8-0.8h-4c-0.4,0-0.8,0.3-0.8,0.8v4 C5.8,10.9,6.1,11.2,6.5,11.2z M7.2,7.2h2.5v2.5H7.2V7.2z"
  />
  <path d="M17.5,5.8h-4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4c0.4,0,0.8-0.3,0.8-0.8S17.9,5.8,17.5,5.8z" />
  <path d="M17.5,9.2h-4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4c0.4,0,0.8-0.3,0.8-0.8S17.9,9.2,17.5,9.2z" />
  <path d="M17.5,13.2h-11c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h11c0.4,0,0.8-0.3,0.8-0.8S17.9,13.2,17.5,13.2z" />
  <path d="M17.5,16.8h-11c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h11c0.4,0,0.8-0.3,0.8-0.8S17.9,16.8,17.5,16.8z" />
</svg>
