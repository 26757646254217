<!--
// Copyright © 2025 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->

<script lang="ts">
  import { IconSize } from '../../types'

  export let size: IconSize = 'small'
</script>

<svg class="next-svg__{size}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path
    d="M20.2502 0.99996C20.2502 0.585746 19.9143 0.249978 19.5001 0.25C19.0859 0.250022 18.7502 0.585826 18.7502 1.00004L18.7503 3.75032L16 3.75019C15.5858 3.75017 15.25 4.08594 15.25 4.50015C15.25 4.91436 15.5857 5.25017 16 5.25019L18.7504 5.25032L18.7505 8.00004C18.7506 8.41425 19.0864 8.75002 19.5006 8.75C19.9148 8.74998 20.2506 8.41417 20.2505 7.99996L20.2504 5.2504L23 5.25054C23.4142 5.25056 23.75 4.91479 23.75 4.50057C23.75 4.08636 23.4143 3.75056 23 3.75054L20.2503 3.7504L20.2502 0.99996Z"
  />
  <path
    d="M6.02763 3.0617C7.79545 1.88048 9.87386 1.25 12 1.25C12.4142 1.25 12.75 1.58579 12.75 2C12.75 2.41421 12.4142 2.75 12 2.75C10.1705 2.75 8.38213 3.2925 6.86098 4.30891C5.33983 5.32531 4.15423 6.76996 3.45412 8.46018C2.75401 10.1504 2.57083 12.0103 2.92774 13.8046C3.28465 15.5989 4.16563 17.2471 5.45927 18.5407C6.7529 19.8344 8.40109 20.7154 10.1954 21.0723C11.9897 21.4292 13.8496 21.246 15.5398 20.5459C17.23 19.8458 18.6747 18.6602 19.6911 17.139C20.7075 15.6179 21.25 13.8295 21.25 12C21.25 11.5858 21.5858 11.25 22 11.25C22.4142 11.25 22.75 11.5858 22.75 12C22.75 14.1261 22.1195 16.2046 20.9383 17.9724C19.7571 19.7402 18.0782 21.1181 16.1139 21.9317C14.1495 22.7453 11.9881 22.9582 9.90278 22.5434C7.81749 22.1287 5.90202 21.1048 4.39861 19.6014C2.89519 18.098 1.87135 16.1825 1.45656 14.0972C1.04177 12.0119 1.25466 9.85046 2.0683 7.88615C2.88194 5.92185 4.2598 4.24293 6.02763 3.0617Z"
  />
  <path
    d="M12 19C13.8962 19 15.4889 17.7202 15.9362 15.9899C16.1443 15.1848 15.8422 15 15.0461 15H8.95386C8.15776 15 7.8557 15.1848 8.0638 15.9899C8.51109 17.7202 10.1038 19 12 19Z"
  />
  <path
    d="M8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12Z"
  />
  <path
    d="M17 10.5C17 11.3284 16.3284 12 15.5 12C14.6716 12 14 11.3284 14 10.5C14 9.67157 14.6716 9 15.5 9C16.3284 9 17 9.67157 17 10.5Z"
  />
</svg>
