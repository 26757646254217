<script lang="ts">
  export let size: 'small' | 'medium' | 'large'
  export let fill: string = 'currentColor'
</script>

<svg class="svg-{size}" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Send">
    <path
      id="Vector"
      d="M17.1564 9.44394L3.40636 2.56894C3.29859 2.51504 3.17754 2.49345 3.05778 2.50675C2.93803 2.52006 2.82467 2.5677 2.73136 2.64394C2.64225 2.71862 2.57574 2.81668 2.5393 2.92709C2.50287 3.0375 2.49795 3.15589 2.52511 3.26894L4.37511 10.0002L2.50011 16.7127C2.47463 16.8071 2.47165 16.9062 2.49143 17.0019C2.5112 17.0977 2.55317 17.1875 2.61396 17.2641C2.67475 17.3407 2.75267 17.402 2.84144 17.443C2.93022 17.484 3.02738 17.5036 3.12511 17.5002C3.22295 17.4996 3.31928 17.476 3.40636 17.4314L17.1564 10.5564C17.2587 10.504 17.3447 10.4243 17.4046 10.3262C17.4646 10.228 17.4964 10.1152 17.4964 10.0002C17.4964 9.88516 17.4646 9.77236 17.4046 9.67421C17.3447 9.57607 17.2587 9.49639 17.1564 9.44394ZM4.09386 15.6939L5.47511 10.6252H10.6251C10.9703 10.6252 11.2501 10.3454 11.2501 10.0002C11.2501 9.65501 10.9703 9.37519 10.6251 9.37519H5.47511L4.09386 4.30644L15.4751 10.0002L4.09386 15.6939Z"
      {fill}
    />
  </g>
</svg>
